import React, { useState } from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import range from 'lodash-es/range';

import documentIcon from '../../../../images/document.svg';
import upArrow from '../../../../images/arrows/red-up-arrow.svg';
import downArrow from '../../../../images/arrows/down-arrow.svg';
import { Collapse } from 'reactstrap';

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 16px 0;

  &&& {
    h3 {
      margin: 0;
      width: 64px;
    }
  }
`;

const TitleText = styled.div`
  display: flex;
  align-items: center;
`;

const Divider = styled.div`
  margin: 0 8px;
`;

const Hr = styled.hr`
  margin: 0;
  border: 1px solid ${prop('theme.colors.borderGrey')};
`;

const NewsletterList = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 32px;
`;

const NewsletterLink = styled.li`
  display: flex;
  align-items: center;
  margin-top: 4px;
  text-decoration: underline;

  img {
    margin-right: 16px;
  }
`;

const propTypes = {};

const defaultProps = {};

// TODO: if the newsletter ever starts up again, see how to make this automatically figure out volume numbers in years
const VOLUME_RANGES_BY_YEAR = {
  2020: range(1, 12),
  2021: range(12, 63),
  2022: range(63, 92)
};

function NewsletterArchive() {
  const [expandedSections, setExpandedSections] = useState({
    2020: false,
    2021: false,
    2022: false
  });

  const volumesByYear = {
    2020: range(1, 12),
    2021: range(12, 63),
    2022: range(63, 92)
  };

  Object.keys(VOLUME_RANGES_BY_YEAR).forEach((year) => {
    volumesByYear[year] = VOLUME_RANGES_BY_YEAR[year].map((volume) => {
      return {
        volume: volume,
        url: `https://public.thesmallexchange.com/newsletters/${year}/Newsletter+Vol+${volume}.pdf`
      };
    });
  });

  return (
    <div>
      {Object.keys(volumesByYear).map((year) => {
        const rangeStart = VOLUME_RANGES_BY_YEAR[year][0];
        const rangeEnd =
          VOLUME_RANGES_BY_YEAR[year][VOLUME_RANGES_BY_YEAR[year].length - 1];

        return (
          <div>
            <Title
              onClick={() =>
                setExpandedSections((prev) => ({
                  ...prev,
                  [year]: !prev[year]
                }))
              }
            >
              <TitleText>
                <h3>{year}</h3>
                <Divider>|</Divider>
                <div>Volumes {`${rangeStart} - ${rangeEnd}`}</div>
              </TitleText>
              <img
                src={expandedSections[year] ? upArrow : downArrow}
                className={
                  expandedSections[year] ? 'expanded-icon' : 'collapsed-icon'
                }
              />
            </Title>
            <Collapse isOpen={expandedSections[year]}>
              <NewsletterList key={`newsletter_year_${year}`}>
                {volumesByYear[year].map((newsletter) => (
                  <NewsletterLink key={`newsletter_vol_${newsletter.volume}`}>
                    <img src={documentIcon} width='15' height='20' />
                    <a href={newsletter.url} target='_blank'>
                      Volume {newsletter.volume}
                    </a>
                  </NewsletterLink>
                ))}
              </NewsletterList>
            </Collapse>
            <Hr />
          </div>
        );
      })}
    </div>
  );
}

NewsletterArchive.propTypes = propTypes;
NewsletterArchive.defaultProps = defaultProps;

export default NewsletterArchive;
